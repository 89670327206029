import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styled from "@emotion/styled/macro";
import { Global, css } from "@emotion/react/macro";
import Table from "./Table";
import Menu from "./Menu";
import Actions from "./Actions";
import CreateTable from "./gui/CreateTable";
import JoinTable from "./gui/JoinTable";

const App = () => {
  return (
    <Router>
      <Container>
        <Global
          styles={css`
            @import url("https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@700&display=swap");
          `}
        />
        <Menu />
        <Actions />
        <Switch>
          <Route exact path="/" children={<CreateTable />} />
          <Route path="/table/:tableId" children={<JoinTable />} />
        </Switch>
        <View>
          <Table />
        </View>
      </Container>
    </Router>
  );
};

export default App;

const Container = styled.div`
  background: #00302c;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const View = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  /* transform: perspective(${50 / Math.sin((15 / 180) * Math.PI)}vh); */
  transform-style: preserve-3d;
`;
