import { Instance, SnapshotIn, SnapshotOut } from "mobx-state-tree";
import { Table } from "../models";

export const ClientTable = Table.volatile(() => ({
  playerId: "",
}))
  .actions((self) => ({
    setPlayerId(playerId: string) {
      self.playerId = playerId;
    },
  }))
  .views((self) => ({
    get player() {
      return self.players.get(self.playerId);
    },
  }))
  // Screen dimension. All sizes and positions are derived from these props.
  .volatile(() => ({
    vw: 800,
    vh: 600,
  }))
  .actions((self) => ({
    updateViewport() {
      self.vw = window.innerWidth;
      self.vh = window.innerHeight;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.updateViewport();
      window.addEventListener("resize", self.updateViewport);
    },
  }))
  .views((self) => ({
    get radius() {
      return Math.min(self.vw, self.vh, 500) / 2;
    },
  }))
  .views((self) => ({
    get x() {
      // Table is centered horitonally.
      return self.vw / 2;
    },
    get y() {
      // Table is placed near the top edge.
      return self.radius * 1.4;
    },
    get rotation() {
      // Return zero if there's no current player.
      if (!self.player) return 0;

      // Return rotation according to the position of the current player.
      return (
        self.playerList.length &&
        ((2 * Math.PI) / self.playerList.length) * self.player.index
      );
    },
  }));

export interface IClientTable extends Instance<typeof ClientTable> {}
export interface IClientTableSnapshotIn
  extends SnapshotIn<typeof ClientTable> {}
export interface IClientTableSnapshotOut
  extends SnapshotOut<typeof ClientTable> {}
