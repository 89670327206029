import React from "react";

const Diamond = () => (
  <svg x="0px" y="0px" viewBox="0 0 58 58">
    <g>
      <polygon fill="currentColor" points="49.5,28 29.5,58 8.5,28 28.5,0" />
    </g>
  </svg>
);

export default Diamond;
