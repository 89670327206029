import React from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled/macro";
import { useHistory } from "react-router-dom";
import Frame from "./Frame";
import client from "../../client";

import Spade from "../icons/Spade";
import Heart from "../icons/Heart";
import Clover from "../icons/Clover";
import Diamond from "../icons/Diamond";

import Oros from "../icons/Oros";
import Copas from "../icons/Copas";
import Espadas from "../icons/Espadas";
import Bastos from "../icons/Bastos";

const suits = ["french", "spanish"];
const icons: Record<string, React.FC[]> = {
  french: [Spade, Heart, Clover, Diamond],
  spanish: [Oros, Copas, Espadas, Bastos],
};

const CreateTable: React.FC = () => {
  const { player, cards } = client.options;

  // Get the link history from `react-router-dom`.
  const history = useHistory();

  /**
   * Function that runs on submit.
   *
   * @param e - Submit event.
   */
  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Send the create table request to the server.
    await client.createTable();

    // Change the URL.
    history.push(`/table/${client.table.tableId}`);
  };

  return (
    <Frame>
      <Form onSubmit={submit}>
        <Field>
          <Label>Your name</Label>
          <Input
            required
            type="text"
            value={player.name}
            onChange={(e) => player.setName(e.target.value)}
          ></Input>
        </Field>
        <Field>
          <Label>Playing cards</Label>
          <SelectContainer>
            <Select
              required
              value={cards.suits}
              onChange={(e) => cards.setSuits(e.target.value)}
            >
              {suits.map((suit) => (
                <option key={suit} value={suit}>
                  {suit}
                </option>
              ))}
            </Select>
            <Symbols>
              {icons[cards.suits].map((Icon, i) => (
                <Symbol key={i}>
                  <Icon />
                </Symbol>
              ))}
            </Symbols>
          </SelectContainer>
        </Field>
        <Button type="submit">Play!</Button>
      </Form>
    </Frame>
  );
};

export default observer(CreateTable);

const Form = styled.form`
  margin: 16px 0;
  padding: 0 8px;
  width: 100%;
  box-sizing: border-box;
`;

const Field = styled.div`
  margin: 16px 0;
`;

const Button = styled.button`
  display: block;

  margin: 32px auto 16px;
  padding: 16px;
  font-size: 20px;
  font-weight: bold;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 4px;
  font-weight: bold;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  box-sizing: border-box;
  &[readonly] {
    background: lightgray;
  }
`;

const Select = styled.select`
  display: block;
  margin: 0;
  height: 32px;
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Symbols = styled.div`
  display: flex;
`;

const Symbol = styled.div`
  align-self: center;
  flex: 0 0 auto;
  height: 24px;
  width: 24px;
  position: relative;
  display: flex;
  align-items: center;

  background: ivory;
  border: 1px solid lightgray;

  &:nth-of-type(odd) {
    color: darkslategray;
  }
  &:nth-of-type(even) {
    color: orangered;
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`;
