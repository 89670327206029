import React from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled/macro";
import Ad from "./Ad";

const GUI: React.FC = ({ children }) => {
  return (
    <Centerer>
      <Container>
        <Icon>
          <span role="img" aria-label="menu">
            🃏
          </span>
        </Icon>
        <Title>naipes.online</Title>
        {children}
      </Container>
      <Ad />
    </Centerer>
  );
};

export default observer(GUI);

const Centerer = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const Container = styled.div`
  width: 300px;
  height: 500px;
  background: white;

  padding: 0 48px;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Icon = styled.div`
  font-size: 64px;
`;

const Title = styled.div`
  position: relative;
  margin: 16px 0;

  color: darkslategray;
  font-size: 48px;
  font-weight: bold;
  font-family: "Yanone Kaffeesatz", sans-serif;

  padding: 8px;

  border-top: 8px double darkslategray;
  border-bottom: 8px double darkslategray;
`;
