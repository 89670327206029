import { types } from "mobx-state-tree";
import { IPositionSnapshotIn } from ".";

export default types
  .model("Position", {
    x: 0,
    y: 0,
    z: 0,
    theta: 0,
  })
  .actions((self) => ({
    update(newValues: Partial<IPositionSnapshotIn>) {
      Object.assign(self, newValues);
    },
  }));
