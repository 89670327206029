import { types } from "mobx-state-tree";

export default types
  .model("App", {
    /**
     * TODO - move this to `app.gui` or something.
     */
    isSnapActive: false,
  })
  .actions((self) => ({
    toggleSnap() {
      self.isSnapActive = !self.isSnapActive;
    },
  }));
