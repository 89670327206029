import { types, getParent } from "mobx-state-tree";
import {
  Card,
  ITable,
  IPlayer,
  ICard,
  IPositionSnapshotIn,
  IPositionSnapshotOut,
} from ".";

const Player = types
  .model("Player", {
    id: types.identifier,
    name: types.string,
    color: types.optional(types.string, "royalblue"),
    hand: types.optional(types.array(types.reference(Card)), []),
  })
  .views((self) => ({
    get table() {
      const table: ITable = getParent(self, 2);
      return table;
    },
    get index(): number {
      const table: ITable = getParent(self, 2);
      return table.playerList.indexOf(self as IPlayer);
    },
  }))
  .actions(
    (self): PlayerActions => ({
      move({ cardIds, position }) {
        self.table.move({ cardIds, position, playerId: self.id });
      },
      pick({ index, cardIds }) {
        self.table.pick({ index, cardIds, playerId: self.id });
      },
      put({ cardIds, position }) {
        self.table.put({ cardIds, position, playerId: self.id });
      },
      shuffle({ cardIds, permutation }) {
        self.table.shuffle({ cardIds, permutation });
      },
      hide({ cardIds }) {
        self.table.hide({ cardIds, playerId: self.id });
      },
      show({ cardIds }) {
        self.table.show({ cardIds, playerId: self.id });
      },
      block({ cardIds }) {
        self.table.block({ cardIds, playerId: self.id });
      },
    })
  );

export default Player;

export type PlayerActions = {
  move(args: { cardIds: ICard["id"][]; position: IPositionSnapshotOut }): void;

  pick(args: { index: number; cardIds: ICard["id"][] }): void;

  put(args: { cardIds: ICard["id"][]; position: IPositionSnapshotIn }): void;

  shuffle(args: { cardIds: ICard["id"][]; permutation: number[] }): void;

  hide(args: { cardIds: ICard["id"][] }): void;

  show(args: { cardIds: ICard["id"][] }): void;

  block(args: { cardIds: ICard["id"][] }): void;
};
