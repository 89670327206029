import io from "socket.io-client";
import {
  onAction,
  applyAction,
  applySnapshot,
  recordPatches,
  ISerializedActionCall,
  IDisposer,
} from "mobx-state-tree";
import { App, Options, IApp, ITableSnapshotOut, IOptions } from "../models";
import { ClientTable, IClientTable } from "./ClientTable";
import { ClientSocket, ClientCallbacks } from "./types";

/**
 * Handles the client connexion and synchronize the table state.
 *
 * Public methods:
 * - connect(): Promise<void>;
 * - joinTable(tableId: string): Promise<void>;
 */
class Client {
  /**
   * The GUI state of the app.
   */
  public state: IApp = App.create();

  /**
   * Table state, shared across connected clients.
   */
  public table: IClientTable = ClientTable.create();

  /**
   * Options state, shared between client and browser.
   */
  public options: IOptions = Options.create();

  /**
   * Flag set to true when the client has connected to the server.
   */
  public isConnected: boolean = false;

  /**
   * Socket connection with the server instance. By default, the connection is
   * not initialized.
   *
   * TODO - The `connect` method shows a warning message in console because it
   * tries to connect to the web server and fails. It only happens when using
   * transports: ["websocket"].
   */
  private socket: ClientSocket = io("/") as ClientSocket;

  /**
   * Function that removes the `onAction` middleware used to send every action
   * to the server.
   */
  private removeOnAction: IDisposer = () => {};

  /**
   * Initialize the connection.
   */
  async connect() {
    await new Promise<void>((resolve) => {
      // Resolves when connected.
      this.socket.on("connect", resolve);
      // Add event handler for player actions.
      this.socket.on("playerAction", this.playerAction.bind(this));
      // Add event handler for table actions.
      this.socket.on("tableAction", this.tableAction.bind(this));
      // Add "refrehs" event handler.
      this.socket.on("refresh", this.refresh.bind(this));
      // Try to join current table on reconnections.
      this.socket.on("reconnect", this.reconnect.bind(this));
      // Open connection.
      this.socket.open();
    });

    console.log(`CLIENT ${this.table.playerId} connected`);

    // Assign player ID
    const playerId = await this.identify();
    this.table.setPlayerId(playerId);

    // TODO - Move this flag to MST.
    this.isConnected = true;
  }

  /**
   * Get UUID and player ID.
   */
  async identify() {
    return await new Promise<string>((resolve, reject) => {
      const uuid = window.localStorage.getItem("uuid");

      this.socket.emit("identify", uuid, (result) => {
        if (typeof result === "object" && "isError" in result) {
          reject(result);
        } else {
          const { uuid, playerId } = result;
          window.localStorage.setItem("uuid", uuid);
          resolve(playerId);
        }
      });
    });
  }

  /**
   * Create a new table and join it.
   */
  async createTable() {
    try {
      const tableId = await new Promise<string>((resolve, reject) => {
        // Send the create event and run `resolve`if everything was OK.
        this.socket.emit("create", this.options.cards, (result) => {
          if (typeof result === "object" && "isError" in result) {
            reject(result);
          } else resolve(result);
        });
      });

      // Join the created table.
      await this.joinTable(tableId);
    } catch (e) {
      // Cannot create the table.
      // TODO - show error message.
    }
  }

  /**
   * Join the table specified by the given ID.
   *
   * @param tableId - The table ID.
   */
  async joinTable(tableId: string) {
    // Get player name from options.
    const { player: playerOptions } = this.options;

    try {
      // Waits until the clients has joined the table and receives an snapshot
      // with the current table state.
      const snapshot = await new Promise<ITableSnapshotOut>(
        (resolve, reject) => {
          // Send the create event and run `resolve`if everything was OK.
          this.socket.emit("join", { tableId, playerOptions }, (result) => {
            if ("isError" in result) reject(result);
            else resolve(result);
          });
        }
      );

      // Update table status.
      this.refresh(snapshot);

      console.log("Joined to table", snapshot);
    } catch (e) {
      // Cannot join the table.
      // TODO - show error message.
      console.log("somehting bad", e);
    }
  }

  async leaveTable() {
    await new Promise<void>((resolve, reject) => {
      // Send the create event and run `resolve`if everything was OK.
      this.socket.emit("leave", (result) => {
        if (result && "isError" in result) reject(result);
        else resolve();
      });
    });

    client.table.reset();
  }

  /**
   * Send a serialized action to the server.
   *
   * @param action - Serialized MST action.
   */
  private sendAction(action: ISerializedActionCall) {
    console.log(`${this.table.playerId}: sendAction`, action);
    this.socket.emit("playerAction", action);
  }

  /**
   * Receive and execute actions from the server. If applying the action throws
   * an error, any change in the state is reverted.
   *
   * @param action - Serialized MST action.
   */
  private playerAction: ClientCallbacks["playerAction"] = (
    playerId,
    action
  ) => {
    console.log(`${this.table.playerId}: receiveAction`, action);

    // Record changes before applying the action.
    const recorder = recordPatches(this.table);

    try {
      applyAction(this.table.players.get(playerId)!, action);
    } catch (e) {
      // Oops! Something's out of sync.
      console.error(`The action execution has failed.`, action, this.table);
      console.error(e);

      // Undo recorded changes.
      recorder.undo();

      // Ask for the current table state in the server.
      this.socket.emit("needRefresh");
    }
  };

  /**
   * Receive and execute actions from the server. If applying the action throws
   * an error, any change in the state is reverted.
   *
   * @param action - Serialized MST action.
   */
  private tableAction: ClientCallbacks["tableAction"] = (action) => {
    console.log(`${this.table.playerId}: receiveAction`, action);

    // Record changes before applying the action.
    const recorder = recordPatches(this.table);

    try {
      applyAction(this.table, action);
    } catch (e) {
      // Oops! Something's out of sync.
      console.error(`The action execution has failed.`, action, this.table);
      console.error(e);

      // Undo recorded changes.
      recorder.undo();

      // Ask for the current table state in the server.
      this.socket.emit("needRefresh");
    }
  };

  /**
   * Overwrite the current table status using the snapshot received from the
   * game server.
   *
   * @param snapshot - Table snapshot.
   */
  private refresh: ClientCallbacks["refresh"] = (snapshot) => {
    console.log(`${this.table.playerId}: refresh`, snapshot);

    // Remove `onAction` listener.
    this.removeOnAction();

    // Reset the table state in client.
    applySnapshot(this.table, snapshot);

    const { player } = this.table;
    if (!player) throw Error("Player is not at the table.");

    // Send every action that the player does.                                                                         layer does.
    this.removeOnAction = onAction(player, this.sendAction.bind(this));
  };

  /**
   * Try to connect to the same table when reconnected.
   *
   * @param attempt - Number of reconnection attempt.
   */
  private reconnect: ClientCallbacks["reconnect"] = async (attempt) => {
    const { tableId } = this.table;
    if (tableId) {
      await this.identify();
      await this.joinTable(tableId);
    }
  };
}

export { Client };

// Export instance as default.
const client = new Client();

// Connect directly.
client.connect();

export default client;

// Add `client` to window object.
// For debugging and stuff.
(window as any).client = client;
