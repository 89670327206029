export class Vector2 {
  x = 0;
  y = 0;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  sum(x: number, y: number): Vector2 {
    this.x += x;
    this.y += y;
    return this;
  }

  min(x: number, y: number): Vector2 {
    this.x -= x;
    this.y -= y;
    return this;
  }

  mult(f: number): Vector2 {
    this.x *= f;
    this.y *= f;
    return this;
  }

  div(f: number): Vector2 {
    this.x /= f;
    this.y /= f;
    return this;
  }

  rotate(β: number): Vector2 {
    const cosβ = Math.cos(β);
    const sinβ = Math.sin(β);
    const { x, y } = this;
    this.x = cosβ * x - sinβ * y;
    this.y = sinβ * x + cosβ * y;
    return this;
  }

  get(): [number, number] {
    return [this.x, this.y];
  }
}
