import { types, onSnapshot, applySnapshot } from "mobx-state-tree";

/**
 * Player options, like the name, appearance and others.
 */
export const PlayerOptions = types
  .model("PlayerOptions", {
    /**
     * Player name.
     */
    name: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setName(name: string) {
      self.name = name;
    },
  }));

/**
 * Options for the deck cards.
 */
export const CardsOptions = types
  .model("CardsOptions", {
    /**
     * Selected suits.
     */
    suits: types.optional(types.string, "french"),

    /**
     * Number of decks.
     */
    decks: types.optional(types.number, 1),

    /**
     * Card value per suit.
     */
    cardValues: types.optional(types.array(types.string), []),

    /**
     * Total number of jokers.
     */
    jokers: types.optional(types.number, 0),
  })
  .actions((self) => ({
    setSuits(suits: typeof self.suits) {
      self.suits = suits;
    },
    setDecks(value: number) {
      self.decks = value;
    },
    setCardsPerSuit(cardValues: string[]) {
      self.cardValues.replace(cardValues);
    },
    setJokers(value: number) {
      self.jokers = value;
    },
  }));

/**
 * Tree of options selected by the user. It synchronizes with the browser's
 * local storage.
 */
export default types
  .model("Options", {
    /**
     * Player options, like the name, appearance and others.
     */
    player: types.optional(PlayerOptions, {}),

    /**
     * Options for the deck cards.
     */
    cards: types.optional(CardsOptions, {}),
  })
  .actions((self) => ({
    /**
     * Initialize options.
     */
    afterCreate() {
      // Get options from local storage.
      const options = window.localStorage.getItem("options");
      if (options) applySnapshot(self, JSON.parse(options));

      // Save options each time they are changed.
      onSnapshot(self, (newSnapshot) => {
        window.localStorage.setItem("options", JSON.stringify(newSnapshot));
      });
    },
  }));
