import { types } from "mobx-state-tree";
import { Suit, Position } from ".";

export default types
  .model("Card", {
    id: types.identifier,
    suit: types.reference(Suit),
    value: types.string,
    position: types.optional(Position, { x: 0, y: 0, z: 0, theta: 0 }),
    isVisible: false,
    isInHand: false,
    blockedBy: "",
    animationIndex: 0,
  })
  .actions((self) => ({
    show() {
      self.isVisible = true;
    },
    hide() {
      self.isVisible = false;
    },
    flip() {
      self.isVisible = !self.isVisible;
    },
  }));
