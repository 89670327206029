import React from "react";
import { observer } from "mobx-react-lite";
import { onAction } from "mobx-state-tree";
import { animated } from "react-spring";
import { useDrag } from "react-use-gesture";

import useTransform from "../../hooks/useTransform";
import useStack from "../../hooks/useStack";
import client from "../../client";
import { IClientTable } from "../../client/ClientTable";
import { ICard, IPlayer } from "../../models";
import { permutate } from "../../models/utils";

import {
  initialPosition,
  normalizeTable,
  indexInHand,
  positionInHand,
} from "./utils";

type PositionProps = {
  card: ICard;
  table: IClientTable;
  player?: IPlayer;
};

const TABLE_ROTATION = 15;

// All of the properties here are the actual object in the store.
const Position: React.FC<PositionProps> = ({
  card,
  player,
  table,
  children,
}) => {
  // Properties from the client.
  const { playerId } = client.table;
  const { isSnapActive } = client.state;

  // Get players around the table.
  const { player: currentPlayer, playerList: players } = table;

  // Get index of the current player.
  const playerIndex = currentPlayer ? currentPlayer.index : -1;

  // Get initial position for this card.
  const transformData = initialPosition({ card, player, players });
  const { transform, transformGoal, interpolate } = useTransform(transformData);
  const [x, y, z, a, b, c, s] = transformData;

  // Move the card each time its coordinates change.
  React.useEffect(() => {
    transform.translate(x, y, z).rotate(a, b, c).scale(s);
  }, [transform, x, y, z, a, b, c, s]);

  // Init useStack hook to handle several cards at the same time.
  const { ref, initStack, updateStack, getStack, clearStack } = useStack({
    payload: { card, transform, transformGoal },
    sorter: (p1, p2) => p1.card.position.z - p2.card.position.z,
    filter: ({ card }) =>
      !!currentPlayer && !(card.isInHand && !currentPlayer.hand.includes(card)),
  });

  // Animation hook for shuffling.
  React.useEffect(() => {
    onAction(client.table, ({ name, args }) => {
      if (name === "shuffle") {
        const [{ cardIds }] = args as any;
        // Rotate cards to make them spin after shuffle.
        if (cardIds.includes(card.id)) {
          const { rotZ } = transformGoal.current;
          const sign = Math.sign(Math.random() - 0.5);
          const rounds = 1 + Math.floor(Math.random() * 2);
          // Animation from hell.
          transform.rotateZ(rotZ + Math.PI * 2 * sign * rounds, true);
        }
      }
    });
  }, [card.id, transform, transformGoal]);

  const bind = useDrag(
    ({
      xy: [x, y],
      velocity,
      direction,
      first,
      last,
      tap,
      cancel,
      elapsedTime,
      distance,
    }) => {
      console.log("aaaa");
      // Exit if there's no current player.
      if (!currentPlayer) return;

      // Exit if the card is owned by other player.
      if (card.isInHand && !currentPlayer.hand.includes(card)) return;

      // SHUFFLING!
      if (isSnapActive && elapsedTime > 1000 && distance === 0) {
        // Select all cards.
        initStack();
        const stack = getStack();
        const cardIds = stack.map((p) => p.card.id);
        const permutation = permutate(cardIds.length);

        // Do the shuffle.
        currentPlayer.shuffle({ cardIds, permutation });

        clearStack();
        cancel && cancel();
        return;
      }

      // FLIP CARDS!
      if (tap) {
        // Select all cards.
        initStack();
        const stack = getStack();

        // Just the current card in case `isSnapActive` is `false`.
        const cardIds = isSnapActive ? stack.map((p) => p.card.id) : [card.id];

        // Reset stack content.
        clearStack();

        return card.isVisible
          ? currentPlayer.hide({ cardIds })
          : currentPlayer.show({ cardIds });
      }

      // Cancel gesture if other player owns the card.
      if (card.blockedBy && card.blockedBy !== playerId) {
        cancel && cancel();
        return;
      }

      // Init gesture.
      if (first) {
        if (isSnapActive) initStack();

        // Block cards
        if (!card.isInHand) {
          const cardIds = getStack()
            .filter(({ card }) => {
              return (
                (!card.isInHand && !card.blockedBy) ||
                card.blockedBy === playerId
              );
            })
            .map(({ card }) => card.id);

          currentPlayer.block({ cardIds });
        }
      }

      // Move cards.
      const isInHandRegion = y >= (client.table.y + client.table.radius) * 1.2;
      const rotZ = Math.atan2(direction[0], -direction[1]);

      if (isSnapActive) {
        // Move all cards.
        getStack().forEach(({ transform }, index) => {
          transform.translate(x, y, index + table.cards.length);
          if (velocity > 0.2) transform.rotate(0, 0, rotZ);
        });
      } else if (isInHandRegion) {
        transform.scale(1.5);
        transform.translate(x, y + 30, 200);
        transform.rotate((-TABLE_ROTATION / 180) * Math.PI, 0, 0);
      } else {
        transform.scale(1);
        transform.translate(x, y, table.cards.length);
        transform.rotateX(0);
        if (velocity > 0.2) transform.rotateZ(rotZ);
      }

      if (last) {
        const { posX, posY, rotZ } = transformGoal.current;
        const angle = ((2 * Math.PI) / players.length) * playerIndex;

        // Use posX and posY to update stack using the final position.
        updateStack([posX, posY]);

        // Get new height and position.
        const stack = getStack();
        const posZ =
          isSnapActive || stack.length < 2
            ? 0
            : stack[stack.length - 2].transformGoal.current.posZ + 1;

        const position = {
          ...normalizeTable({ x: posX, y: posY }, angle),
          theta: rotZ - angle,
          z: posZ,
        };

        // Get card ids.
        const cardIds = isSnapActive
          ? getStack()
              .filter(({ card }) => {
                return (
                  (!card.isInHand && !card.blockedBy) ||
                  card.blockedBy === playerId
                );
              })
              .map(({ card }) => card.id)
          : [card.id];

        if (isInHandRegion) {
          currentPlayer.pick({
            cardIds,
            index: indexInHand({ playerId, table, x }),
          });

          // Always animate when cards are picked/reordered.
          const [px, py, pz, ra, rb, rc, s] = positionInHand({
            card,
            player: currentPlayer,
          });
          transform.translate(px, py, pz).rotate(ra, rb, rc).scale(s);
        } else if (card.isInHand) {
          currentPlayer.put({ cardIds, position });
        } else if (!card.isInHand) {
          currentPlayer.move({ cardIds, position });
        }
        clearStack();
      }
    },
    { filterTaps: true }
  );

  const zIndex = player ? player.hand.indexOf(card) : card.position.z;

  return (
    <animated.div
      {...bind()}
      ref={ref as React.RefObject<HTMLDivElement>}
      style={{
        top: 0,
        left: 0,
        zIndex,
        position: "absolute",
        transformStyle: "preserve-3d",
        transform: interpolate(),
        touchAction: "none",
      }}
    >
      {children}
    </animated.div>
  );
};

export default observer(Position);
