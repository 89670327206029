export const permutate = (length: number): number[] => {
  const positions = Array(length)
    .fill(0)
    .map((_, i) => i);
  for (let i = positions.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i);
    const temp = positions[i];
    positions[i] = positions[j];
    positions[j] = temp;
  }
  return positions;
};

export const reorder = <T>(list: T[], permutation: number[]): T[] =>
  list.map((_, i) => list[permutation[i]]);

export const generateId = (...args: Object[]): string => {
  const str = JSON.stringify(args);

  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }

  return `${hash}`;
};
