import React from "react";

type CardProps = {
  stroke?: string;
  fill?: string;
};

const Card: React.FC<CardProps> = ({ stroke, fill }) => (
  <svg viewBox="0 0 24 24">
    <path
      id="path5345"
      d="m 17.5,3 h -11 c -1,0 -2,1 -2,2 v 14 c 0,1 1,2 2,2 h 11 c 1,0 2,-1 2,-2 V 5 c 0,-1 -1,-2 -2,-2 z m 0,16 H 6.5 V 5 h 11 z"
    />
  </svg>
);

export default Card;
