import React from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled/macro";
import { useParams } from "react-router-dom";
import Frame from "./Frame";
import client from "../../client";

const CreateRoom: React.FC = () => {
  const { player } = client.options;

  // Get the table ID from the URL.
  const { tableId } = useParams<{ tableId: string }>();

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await client.joinTable(tableId);
  };

  // Do not render anything if the client is already connected to this table.
  if (client.table.tableId === tableId) return null;

  return (
    <Frame>
      <Form onSubmit={submit}>
        <Field>
          <Label>Player:</Label>
          <Input
            required
            type="text"
            value={player.name}
            onChange={(e) => player.setName(e.target.value)}
          ></Input>
        </Field>
        <Field>
          <Label>Table ID:</Label>
          <Input required readOnly type="text" value={tableId}></Input>
        </Field>
        <Button type="submit">Join</Button>
      </Form>
    </Frame>
  );
};

export default observer(CreateRoom);

const Form = styled.form`
  margin: 16px 0;
  padding: 0 8px;
  width: 100%;
  box-sizing: border-box;
`;

const Field = styled.div`
  margin: 16px 0;
`;

const Button = styled.button`
  display: block;

  margin: 32px auto 16px;
  padding: 16px;
  font-size: 20px;
  font-weight: bold;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 4px;
  font-weight: bold;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  box-sizing: border-box;
  &[readonly] {
    background: #efefef;
  }
`;
