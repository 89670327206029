import { types, resolveIdentifier, applySnapshot } from "mobx-state-tree";
import {
  Player,
  Card,
  Suit,
  IPositionSnapshotIn,
  IPlayer,
  ICard,
  IPositionSnapshotOut,
} from ".";

// TODO - Create different actions for clients and the server.
export default types
  .model("Table", {
    tableId: types.maybe(types.string),
    suits: types.optional(types.array(Suit), []),
    cards: types.optional(types.array(Card), []),
    players: types.optional(types.map(Player), {}),
  })
  .views((self) => ({
    /**
     * Return the players in array format.
     */
    get playerList() {
      return [...self.players.values()];
    },
  }))
  .actions((self) => {
    const resolvePlayer = (id: IPlayer["id"]): IPlayer => {
      const player = resolveIdentifier(Player, self.players, id);
      if (!player) throw Error(`No player found with id ${id}.`);
      else return player;
    };

    const resolveCard = (id: ICard["id"]): ICard => {
      const card = resolveIdentifier(Card, self.cards, id);
      if (!card) throw Error(`No card found with id ${id}.`);
      else return card;
    };

    const move: Move = ({ cardIds, position, playerId }) => {
      // Position is the base one.
      const { x, y, z, theta } = position;
      cardIds
        .map((id) => resolveCard(id))
        .forEach((card, i) => {
          // Each card is placed over ththisprevious one.
          card.position.update({ x, y, z: z + i, theta });
          card.blockedBy = "";
        });
    };

    const pick: Pick = ({ cardIds, playerId, index }) => {
      const player = resolvePlayer(playerId);
      const cards = cardIds.map((cardId) => resolveCard(cardId));

      // Mark cards in hand.
      cards.forEach((card) => (card.isInHand = true));

      // Get other cards in hand.
      const hand = player.hand.filter((c) => !cardIds.includes(c.id));

      // Add the card in the specified index.
      hand.splice(index, 0, ...cards);

      // Replace hand content.
      player.hand.replace(hand);
    };

    const put: Put = ({ cardIds, playerId, position }) => {
      const player = resolvePlayer(playerId);
      const cards = cardIds.map((cardId) => resolveCard(cardId));

      // Remove card from hand.
      cards.forEach((card, i) => {
        player.hand.remove(card);

        // Mark this card not in hand.
        card.isInHand = false;
        card.blockedBy = "";

        // Update card position.
        const { x, y, z, theta } = position;
        card.position.update({ x, y, z: z ? z + i : i, theta });
      });
    };

    // Reorder the cards inside the deck. TODO.
    const shuffle: Shuffle = ({ cardIds, permutation }) => {
      const cards = cardIds.map((id) => resolveCard(id));

      if (cards.length !== permutation.length)
        throw Error(
          `Permutation has a different length (${permutation.length}) than the stack (${cards.length}).`
        );

      permutation
        .map((i) => cards[i])
        .forEach((card, z) => card.position.update({ z }));
    };

    const hide: Hide = ({ cardIds, playerId }) => {
      cardIds
        .map((id) => resolveCard(id))
        .forEach((card, index) => {
          card.hide();
          card.animationIndex = cardIds.length - 1 - index;
        });
    };

    const show: Show = ({ cardIds, playerId }) => {
      cardIds
        .map((id) => resolveCard(id))
        .forEach((card, index) => {
          card.show();
          card.animationIndex = cardIds.length - 1 - index;
        });
    };

    const block: Block = ({ cardIds, playerId }) => {
      cardIds
        .map((id) => resolveCard(id))
        .forEach((card) => {
          if (card.blockedBy && card.blockedBy !== playerId) {
            throw Error(
              `Player "${playerId}" cannot block "${card.id}" (it's already blocked by "${card.blockedBy}").`
            );
          }
          card.blockedBy = playerId;
        });
    };

    const unblock: Unblock = ({ cardIds, playerId }) => {
      cardIds
        .map((id) => resolveCard(id))
        .forEach((card) => (card.blockedBy = ""));
    };

    const addPlayer: AddPlayer = ({ id, name }) => {
      const player = self.players.get(id);
      if (player) {
        player.name = name;
      } else {
        self.players.set(id, { id, name });
      }
    };

    const removePlayer: RemovePlayer = ({ id }) => {
      const player = self.players.get(id);
      if (player) {
        // Place all cards in hand over the table.
        const cardIds = player.hand.map((card) => card.id);
        const position = { x: 0, y: 0 };
        player.hide({ cardIds });
        player.put({ cardIds, position });

        self.players.delete(id);
      }
    };

    /**
     * Remove all the table state, including cars and players.
     */
    const reset = () => {
      applySnapshot(self, {});
    };

    return {
      move,
      pick,
      put,
      shuffle,
      hide,
      show,
      block,
      unblock,
      addPlayer,
      removePlayer,
      reset,
    };
  });

// Action types
type Move = (args: {
  cardIds: ICard["id"][];
  position: IPositionSnapshotOut;
  playerId: IPlayer["id"];
}) => void;

type Pick = (args: {
  cardIds: ICard["id"][];
  playerId: IPlayer["id"];
  index: number;
}) => void;

type Put = (args: {
  cardIds: ICard["id"][];
  playerId: IPlayer["id"];
  position: IPositionSnapshotIn;
}) => void;

type Shuffle = (args: {
  cardIds: ICard["id"][];
  permutation: number[];
}) => void;

type Hide = (args: { cardIds: ICard["id"][]; playerId: IPlayer["id"] }) => void;

type Show = (args: { cardIds: ICard["id"][]; playerId: IPlayer["id"] }) => void;

type Block = (args: {
  cardIds: ICard["id"][];
  playerId: IPlayer["id"];
}) => void;

type Unblock = (args: {
  cardIds: ICard["id"][];
  playerId: IPlayer["id"];
}) => void;

type AddPlayer = (args: { id: string; name: string }) => void;
type RemovePlayer = (args: { id: string }) => void;
