import React from "react";
import { useSpring, interpolate as springInterpolate } from "react-spring";

type TransformData = [number, number, number, number, number, number, number];

const useTransform = ([x, y, z, a, b, c, s]: TransformData) => {
  const initialValues = {
    posX: x,
    posY: y,
    posZ: z,
    rotX: a,
    rotY: b,
    rotZ: c,
    scal: s,
  };

  const isImmediate = React.useRef(false);

  const [{ posX, posY, posZ, rotX, rotY, rotZ, scal }, setSpring] = useSpring<
    typeof transformGoal.current
  >(() => ({
    ...initialValues,
    immediate: (key: string) => {
      return isImmediate.current;
    },
  }));

  const transformGoal: React.MutableRefObject<{
    posX: number;
    posY: number;
    posZ: number;
    rotX: number;
    rotY: number;
    rotZ: number;
    scal: number;
  }> = React.useRef(initialValues);

  const set = React.useCallback(
    (goal: Parameters<typeof setSpring>[0]) => {
      transformGoal.current = { ...transformGoal.current, ...goal };
      setSpring(goal);
    },
    [setSpring]
  );

  const immediate = React.useCallback(
    (value: boolean) => {
      isImmediate.current = value;
    },
    [isImmediate]
  );

  const transform = React.useMemo(() => {
    const instance = {
      translateX: (posX: number, immediate?: boolean) => {
        if (immediate) isImmediate.current = true;
        set({ posX });
        isImmediate.current = false;
        return instance;
      },
      translateY: (posY: number, immediate?: boolean) => {
        if (immediate) isImmediate.current = true;
        set({ posY });
        isImmediate.current = false;
        return instance;
      },
      translateZ: (posZ: number, immediate?: boolean) => {
        if (immediate) isImmediate.current = true;
        set({ posZ });
        isImmediate.current = false;
        return instance;
      },
      translate: (
        posX: number,
        posY: number,
        posZ: number,
        immediate?: boolean
      ) => {
        if (immediate) isImmediate.current = true;
        set({ posX, posY, posZ });
        isImmediate.current = false;
        return instance;
      },
      rotateX: (rotX: number, immediate?: boolean) => {
        if (immediate) isImmediate.current = true;
        set({ rotX });
        isImmediate.current = false;
        return instance;
      },
      rotateY: (rotY: number, immediate?: boolean) => {
        if (immediate) isImmediate.current = true;
        set({ rotY });
        isImmediate.current = false;
        return instance;
      },
      rotateZ: (rotZ: number, immediate?: boolean) => {
        if (immediate) isImmediate.current = true;
        set({ rotZ });
        isImmediate.current = false;
        return instance;
      },
      rotate: (
        rotX: number,
        rotY: number,
        rotZ: number,
        immediate?: boolean
      ) => {
        if (immediate) isImmediate.current = true;
        set({ rotX, rotY, rotZ });
        isImmediate.current = false;
        return instance;
      },
      scale: (scal: number, immediate?: boolean) => {
        if (immediate) isImmediate.current = true;
        set({ scal });
        isImmediate.current = false;
        return instance;
      },
      immediate,
    };
    return instance;
  }, [set, immediate]);

  const interpolate = React.useCallback(() => {
    return springInterpolate(
      [posX, posY, posZ, rotX, rotY, rotZ, scal],
      (posX, posY, posZ, rotX, rotY, rotZ, scale) =>
        `translate3d(calc(${posX}px - 50%), calc(${posY}px - 50%), ${posZ}px) rotateZ(${rotZ}rad) rotateX(${rotX}rad) rotateY(${rotY}rad) scale(${scale})`
    );
  }, [posX, posY, posZ, rotX, rotY, rotZ, scal]);

  const normalize = React.useCallback(() => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const { posX, posY, rotZ } = transformGoal.current;
    return {
      x: (posX / viewportWidth) * 100,
      y: (posY / viewportHeight) * 100,
      theta: rotZ,
    };
  }, []);

  return {
    transform,
    interpolate,
    normalize,
    transformGoal,
  };
};

export default useTransform;
