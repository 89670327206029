import React from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled/macro";
import CardIcon from "./icons/Card";
import CardStackIcon from "./icons/CardStack";
import client from "../client";

const Actions = () => {
  const { isSnapActive, toggleSnap } = client.state;

  return (
    <Container>
      <Button onClick={toggleSnap}>
        {isSnapActive ? <CardStackIcon /> : <CardIcon />}
      </Button>
    </Container>
  );
};

export default observer(Actions);

const Container = styled.div`
  z-index: 50;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const Button = styled.button`
  display: block;
  box-sizing: border-box;
  width: 40px;
  height: 40px;

  background: ivory;
  border: 0;
  border-radius: 20px;
  outline: none;
  box-shadow: 0 3px 12px grey;

  margin: 8px;
  padding: 0;

  svg {
    width: 24px;
    height: 24px;
  }
`;
