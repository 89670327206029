import React from "react";
import styled from "@emotion/styled/macro";
import Spade from "./icons/Spade";
import Heart from "./icons/Heart";
import Clover from "./icons/Clover";
import Diamond from "./icons/Diamond";
import { observer } from "mobx-react-lite";
import client from "../client";

const symbols = [Spade, Heart, Clover, Diamond];

const colors = [
  "royalblue",
  "orangered",
  "green",
  "yellow",
  "turquoise",
  "purple",
  "orange",
  "grey",
];

const TableCloth: React.FC = () => {
  // Get all players and the current player ID.
  const { radius, x, y, rotation, playerList } = client.table;

  return (
    <Green rotation={rotation} radius={radius} x={x} y={y}>
      {playerList.map((p) => {
        // The rotation of the player at the table.
        const playerRotation = -((2 * Math.PI) / playerList.length) * p.index;

        // Symbol for this player.
        const Symbol = symbols[p.index % symbols.length];

        // Color for this player.
        const color = colors[p.index % colors.length];

        return (
          <PlayerPos key={p.id} color={color} rot={playerRotation}>
            <PlayerInfo>
              <Symbol />
              <PlayerName>
                {p.name}: {p.hand.length}
              </PlayerName>
            </PlayerInfo>
          </PlayerPos>
        );
      })}
    </Green>
  );
};

export default observer(TableCloth);

const Green = styled.div<{
  rotation: number;
  radius: number;
  x: number;
  y: number;
}>`
  position: absolute;
  top: ${({ y }) => y}px;
  left: ${({ x }) => x}px;
  transform: translate(-50%, -50%) rotateZ(${({ rotation }) => rotation}rad);
  transform-style: preserve-3d;
  width: ${({ radius }) => radius * 2}px;
  height: ${({ radius }) => radius * 2}px;
  border-radius: 50%;
  border: ${({ radius }) => radius * 0.02}px solid #009618;
  box-shadow: 0 0 0 ${({ radius }) => radius * 0.04}px limegreen,
    0 0 ${({ radius }) => radius * 0.4}px 0 rgba(255, 255, 0, 0.5);
  box-sizing: border-box;
  background: limegreen;
`;

const PlayerPos = styled.div<{
  rot: number;
  color: string;
}>`
  position: absolute;
  width: 6%;
  height: 100%;
  top: 0;
  left: 50%;
  color: ${({ color }) => color};
  transform: translateX(-50%) rotateZ(${({ rot }) => rot}rad);

  > * {
    position: absolute;
    bottom: 5%;
  }
`;

const PlayerInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: sans-serif;
`;

const PlayerName = styled.div`
  white-space: nowrap;
  margin-top: 0.6em;
`;
