import React from "react";
import styled from "@emotion/styled/macro";
import { keyframes } from "@emotion/react/macro";
import { observer } from "mobx-react-lite";

import { ICard } from "../models";
import { IClientTable } from "../client/ClientTable";
import Spade from "./icons/Spade";
import Heart from "./icons/Heart";
import Clover from "./icons/Clover";
import Diamond from "./icons/Diamond";
import Oros from "./icons/Oros";
import Copas from "./icons/Copas";
import Espadas from "./icons/Espadas";
import Bastos from "./icons/Bastos";

type CardProps = {
  card: ICard;
  table: IClientTable;
};

const iconMap: Record<string, () => JSX.Element> = {
  spades: Spade,
  hearts: Heart,
  clovers: Clover,
  diamonds: Diamond,
  oros: Oros,
  copas: Copas,
  espadas: Espadas,
  bastos: Bastos,
};

const playerColors = [
  "royalblue",
  "orangered",
  "green",
  "yellow",
  "turquoise",
  "purple",
  "orange",
  "grey",
];

const FrameCard: React.FC<CardProps> = ({ card, table }) => {
  const { suit, value, blockedBy, animationIndex, isInHand } = card;
  const Symbol = iconMap[suit.name];
  const playerColor =
    playerColors[
      table.playerList.findIndex((p) => p.id === blockedBy) %
        playerColors.length
    ];
  const currentPlayer = table.player!;

  const isVisible =
    isInHand && !currentPlayer.hand.includes(card) ? false : card.isVisible;

  return (
    <Bouncing
      isVisible={isVisible}
      animationIndex={animationIndex}
      style={{ "--card-height": `${table.radius * 0.3}px` } as any}
    >
      <Frame
        color={suit.color}
        borderColor={isInHand ? "transparent" : playerColor}
        isVisible={isVisible}
        animationIndex={animationIndex}
      >
        <Front>
          <CornerValue>
            <div>{value}</div>
            {suit.miniIcons && <Symbol />}
          </CornerValue>
          <SymbolContainer>
            <Symbol />
          </SymbolContainer>
          <CornerValue>
            <div>{value}</div>
            {suit.miniIcons && <Symbol />}
          </CornerValue>
        </Front>
        <Back />
      </Frame>
    </Bouncing>
  );
};

export default observer(FrameCard);

const showCard = keyframes`
  from {
    transform: translateZ(calc(var(--card-height) * 0.01 * 2)) rotateX(0deg);
  }
  50% {
    transform: translateZ(calc(var(--card-height) * 0.75 * 2)) rotateX(-5deg);
  }
  to {
    transform: translateZ(calc(var(--card-height) * 0.01 * 2)) rotateX(0deg);
  }
`;

const hideCard = keyframes`
  from {
    transform: translateZ(calc(var(--card-height) * 0.01 * 2)) rotateX(0deg);
  }
  50% {
    transform: translateZ(calc(var(--card-height) * 0.74 * 2)) rotateX(-5deg);
  }
  to {
    transform: translateZ(calc(var(--card-height) * 0.01 * 2)) rotateX(0deg);
  }
`;

type FrameProps = {
  color: string;
  isVisible: boolean;
  borderColor: string;
  animationIndex: number;
};

const Bouncing = styled.div<{ isVisible: boolean; animationIndex: number }>`
  transform-style: preserve-3d;
  transform: translateZ(calc(var(--card-height) / 100));
  animation: ${({ isVisible }) => (isVisible ? showCard : hideCard)} 0.5s
    ease-out ${({ animationIndex }) => animationIndex * 16}ms;
`;

const Frame = styled("div")<FrameProps>`
  width: calc(var(--card-height) * 0.66); /* 132px */
  height: calc(var(--card-height)); /* 200px */

  position: relative;
  transform-style: preserve-3d;
  cursor: grab;
  user-select: none;

  color: ${({ color }) => color};
  font-size: calc(var(--card-height) * 0.3); /* 64px */
  font-weight: bold;

  & > div {
    border-color: black;
    box-sizing: border-box;
  }

  box-shadow: 0 0 2px 2px ${({ borderColor }) => borderColor || "transparent"};
  border-radius: calc(var(--card-height) / 10); /* 20px */

  /* Show and Hide animations */
  transform: ${({ isVisible }) => (isVisible ? "" : "rotateY(180deg)")};
  transition: transform 0.5s ease-in-out
      ${({ animationIndex }) => animationIndex * 16}ms,
    box-shadow 0.5s;
`;

const Front = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border: calc(var(--card-height) / 100) solid black; /* 2px */
  border-radius: calc(var(--card-height) / 10); /* 20px */
  background: ivory;
  backface-visibility: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  transform-style: preserve-3d;

  & > div:last-child {
    transform: rotateZ(180deg);
  }
`;

const CornerValue = styled.div`
  align-self: stretch;
  flex: 0 0 auto;
  font-size: calc(var(--card-height) / 100 * 20);
  letter-spacing: calc(var(--card-height) * -0.02);
  width: 25%;
  box-sizing: border-box;
  padding-top: calc(var(--card-height) / 100 * 2.5);
  padding-left: calc(var(--card-height) / 100 * 2.5);
  position: relative;

  svg {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const SymbolContainer = styled.div`
  align-self: center;
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  svg {
    width: 100%;
    height: auto;
    max-height: 70%;
    display: block;
  }
`;

const Back = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border: calc(var(--card-height) / 100) solid black; /* 2px */
  border-radius: calc(var(--card-height) / 10); /* 20px */
  /* background: steelblue; */
  background: repeating-linear-gradient(
      45deg,
      transparent 0,
      transparent calc(var(--card-height) * 0.0667),
      steelblue calc(var(--card-height) * 0.0667),
      steelblue calc(var(--card-height) * 0.1)
    ),
    repeating-linear-gradient(
      -45deg,
      transparent 0,
      transparent calc(var(--card-height) * 0.0667),
      steelblue calc(var(--card-height) * 0.0667),
      steelblue calc(var(--card-height) * 0.1)
    ),
    ivory;
  box-shadow: inset 0 0 0 calc(var(--card-height) * 0.0667) ivory,
    inset 0 0 0px calc(var(--card-height) * 0.093) steelblue;

  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
`;
