import React from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled/macro";
import client from "../client";
import MenuIcon from "./icons/Menu";
import CloseIcon from "./icons/Close";
import ShareIcon from "./icons/Share";
import ExitIcon from "./icons/Exit";

declare var navigator: Navigator & {
  share: (shareData: {
    url?: string;
    text?: string;
    title?: string;
    files?: string[];
  }) => Promise<void>;
};

const Menu = () => {
  // Get the link history from `react-router-dom`.
  const history = useHistory();

  const [isMenuOpen, setMenuOpen] = React.useState(false);

  const shareLink = async () => {
    const url = document.location.href;
    try {
      await navigator.share({ url, title: "naipes.online" });
    } catch (e) {
      // Share is not implemented or has failed.
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(url);
        alert("Link copied to clipboard!");
      }
    }
  };

  const leaveGame = () => {
    if (window.confirm("You're about to leave the game. Are you sure?")) {
      setMenuOpen(false);
      history.push("/");
      client.leaveTable();
    }
  };

  return (
    <MenuContainer>
      <Circle onClick={() => setMenuOpen(!isMenuOpen)}>
        {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
      </Circle>
      {isMenuOpen && (
        <>
          <MenuItem onClick={shareLink}>
            <MenuLabel>Share</MenuLabel>
            <Circle>
              <ShareIcon />
            </Circle>
          </MenuItem>
          <MenuItem onClick={leaveGame}>
            <MenuLabel>Exit</MenuLabel>
            <Circle>
              <ExitIcon />
            </Circle>
          </MenuItem>
        </>
      )}
    </MenuContainer>
  );
};

export default observer(Menu);

const MenuContainer = styled.div`
  z-index: 50;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 40px;
  height: 40px;

  background: ivory;
  border: 0;
  border-radius: 20px;
  outline: none;
  box-shadow: 0 3px 12px grey;

  margin: 8px;
  padding: 0;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const MenuItem = styled.button`
  appearance: none;
  border: none;
  background: none;
  padding: 0;
`;

const MenuLabel = styled.div`
  color: white;
  text-shadow: 0 1px black;
`;
