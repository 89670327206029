import React from "react";

type ElementRef = React.RefObject<HTMLElement>;

// Return `true` if both Rects are colliding.
const areColliding = (
  rectA: DOMRect | ClientRect,
  rectB: DOMRect | ClientRect
) => {
  return !(
    rectA.right < rectB.left ||
    rectA.left > rectB.right ||
    rectA.bottom < rectB.top ||
    rectA.top > rectB.bottom
  );
};

// Object where all payloads are stored (by ref).
const refMap = new Map<ElementRef, unknown>();

const useCollision = <Payload = unknown>(payload: Payload) => {
  // Creates an HTMLElement ref.
  const ref = React.useRef<HTMLElement>(null);

  // Method that returns all the elements colliding with this one
  const getCollisions = (xy?: [number, number]) => {
    // Return an empty array if ref is not defined.
    if (!ref.current) return [];

    let refRect = ref.current.getBoundingClientRect();

    if (xy) {
      // Replace refRect by the final position.
      const { width, height } = refRect;
      refRect = new DOMRect(
        xy[0] - width / 2,
        xy[1] - height / 2,
        width,
        height
      );
    }

    return [...refMap.entries()].filter(([otherRef]) => {
      if (ref === otherRef || !otherRef.current) return false;

      const otherRefRect = otherRef.current.getBoundingClientRect();
      return areColliding(refRect, otherRefRect);
    }) as [ElementRef, Payload][];
  };

  // Save payload by ref.
  refMap.set(ref, payload);

  // Remove elementRef when element is unmount.
  React.useEffect(() => {
    return () => {
      refMap.delete(ref);
    };
  }, []);

  // Return the overlap list.
  return { ref, getCollisions };
};

export default useCollision;
