import React from "react";
import styled from "@emotion/styled/macro";

const Ad = () => {
  return <Container>AD</Container>;
};

export default Ad;

const Container = styled.div`
  background: cornflowerblue;
  color: white;
  font-size: 32px;
  font-weight: bold;
  width: 300px;
  height: 100px;
  text-align: center;
  line-height: 100px;
`;
