import client from "../../client";
import { Vector2 } from "../../math/vector";
import { ICard, IPlayer, ITable } from "../../models";

type TransformData = [number, number, number, number, number, number, number];

export const getTableCenter = (): [number, number] => [
  client.table.x,
  client.table.y,
];

export const getHandCenter = (): [number, number] => [
  window.innerWidth / 2,
  window.innerHeight - window.innerWidth / 10,
];

export const normalizeTable = (
  { x, y }: { x: number; y: number },
  angle: number
) => {
  const [x2, y2] = new Vector2(x, y)
    .min(...getTableCenter())
    .div(client.table.radius)
    .rotate(-angle)
    .get();
  return { x: x2, y: y2 };
};

export const positionInTable = ({
  card,
  players,
}: {
  card: ICard;
  players: IPlayer[];
}): TransformData => {
  // Calculate the base rotation for the current player.
  const { player } = client.table;
  const playerIndex = player ? player.index : 0;
  const playerRot =
    players.length && ((2 * Math.PI) / players.length) * playerIndex;

  // Get the position attributes.
  const { x, y, z, theta } = card.position;

  // Transform x and y coordinates.
  const [x2, y2] = new Vector2(x, y)
    .rotate(playerRot)
    .mult(client.table.radius)
    .sum(...getTableCenter())
    .get();

  // Return an array with the transformations.
  return [x2, y2, z, 0, 0, theta + playerRot, 1];
};

export const indexInHand = ({
  playerId,
  table,
  x,
}: {
  playerId: string;
  table: ITable;
  x: number;
}) => {
  const player = table.players.get(playerId);
  const length = player ? player.hand.length : 1;
  const factor = (50 * 5) / Math.max(5, length);
  return Math.max(
    Math.round((length - 1) / 2 + (x - 0.5 * window.innerWidth) / factor),
    0
  );
};

export const positionInHand = ({
  card,
  player,
}: {
  card: ICard;
  player: IPlayer;
}): TransformData => {
  const { length } = player.hand;
  const index = player.hand.indexOf(card);
  const offset = index - (length - 1) / 2;
  const offsetNorm = offset / Math.max(5, length);

  const [x, y] = new Vector2(...getHandCenter())
    .sum(250 * offsetNorm, (1 - Math.cos(offsetNorm * Math.PI)) * 15)
    .get();

  // Get the correct Z value for each card in hand.
  const z = 100 + (2 * (index / length) - 1);

  return [x, y, z, 0, 0, offsetNorm * 0.25 * Math.PI, 1.5];
};

export const positionInPlayer = ({
  card,
  player,
  players,
}: {
  card: ICard;
  player: IPlayer;
  players: IPlayer[];
}): TransformData => {
  // Calculate the position of this player.

  // Calculate the base rotation for the current player.
  const playerRot =
    players.length &&
    ((2 * Math.PI) / players.length) *
      (client.table.player!.index - player.index);

  const { length } = player.hand;
  const index = player.hand.indexOf(card);

  const [x, y] = new Vector2(0.01 * (index - length / 2), 0.9)
    .rotate(playerRot)
    .mult(client.table.radius)
    .sum(...getTableCenter())
    .get();

  return [x, y, 100, Math.PI / 3, 0, playerRot, 1];
};

export const initialPosition = ({
  card,
  player,
  players,
}: {
  card: ICard;
  player?: IPlayer;
  players?: IPlayer[];
}) => {
  // Position for cards picked by the current player.
  if (player && player === client.table.player) {
    return positionInHand({ card, player });
  }

  // Position for cards picked by any other player.
  if (player && players) {
    return positionInPlayer({ card, player, players });
  }

  // Positions for cards on the table.
  if (card && players) {
    return positionInTable({ card, players });
  }

  throw Error("Error getting initial position.");
};
