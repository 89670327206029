import React from "react";
import styled from "@emotion/styled/macro";
import { observer } from "mobx-react-lite";
import Position from "./Position";
import Card from "./Card";
import TableCloth from "./TableCloth";
import client from "../client";

type ByKey = (e1: JSX.Element, e2: JSX.Element) => number;

const byKey: ByKey = (e1, e2) => {
  if (!e1.key || !e2.key) return 0;
  if (e1.key < e2.key) return -1;
  if (e1.key > e2.key) return 1;
  return 0;
};

const Table = () => {
  const { table } = client;
  const { cards, playerList: players } = client.table;

  const stackCards = cards
    .filter((c) => !c.isInHand)
    .map((card) => (
      <Position key={card.id} card={card} table={table}>
        <Card card={card} table={table} />
      </Position>
    ));

  const playerCards = players
    .map((player) =>
      player.hand.map((card) => (
        <Position key={card.id} card={card} player={player} table={table}>
          <Card card={card} table={table} />
        </Position>
      ))
    )
    .reduce((acc, cards) => acc.concat(cards), []);

  return (
    <TableContainer>
      <TableCloth />
      {[...stackCards, ...playerCards].sort(byKey)}
    </TableContainer>
  );
};

export default observer(Table);

const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transform: rotateX(15deg);
`;
