import React from "react";
import useCollision from "./useCollision";

const useStack = <Payload = unknown>({
  payload,
  sorter,
  filter = () => true,
}: {
  payload: Payload;
  sorter: (p1: Payload, p2: Payload) => number;
  filter?: (p: Payload) => boolean;
}) => {
  const { ref, getCollisions } = useCollision(payload);

  type Collisions = ReturnType<typeof getCollisions>;

  // Store collisions in a ref.
  const initialCollisions = React.useRef<Collisions>([]);
  const currentCollisions = React.useRef<Collisions>([]);

  const sorted = (collisions: React.MutableRefObject<Collisions>) =>
    collisions.current.map(([_ref, handlers]) => handlers).sort(sorter);

  const initStack = () => {
    initialCollisions.current = getCollisions().filter(([_ref, payload]) =>
      filter(payload)
    );
  };

  const updateStack = (xy?: [number, number]) => {
    currentCollisions.current = getCollisions(xy)
      .filter(([_ref, payload]) => filter(payload))
      .filter(([ref]) => {
        return !initialCollisions.current.find(
          ([otherRef]) => otherRef === ref
        );
      });
  };

  const getStack = () => {
    return [
      ...sorted(currentCollisions),
      ...sorted(initialCollisions),
      payload,
    ];
  };

  const clearStack = () => {
    initialCollisions.current = [];
    currentCollisions.current = [];
  };

  return { ref, initStack, updateStack, getStack, clearStack };
};

export default useStack;
